import { Inject, Injectable } from "@angular/core";
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from "@azure/msal-angular";
import {
  AccountInfo,
  AuthenticationResult,
  EventType,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from "@azure/msal-browser";
import { Subject } from "rxjs";
import { filter, mergeMap, takeUntil } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class MsalAuthService {
  public loggedIn = false;
  public loggedInUser: string;
  // tslint:disable-next-line:variable-name
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private cookie: CookieService
  ) {}

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.loginWithPopup();
    } else {
      this.loginWithRedirect();
    }
  }

  updateLoggedInStatus() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoggedIn();
        this.checkAndSetActiveAccount();
        sessionStorage.setItem("userName", this.loggedInUser);
        this.cookie.set("accessToken", sessionStorage.getItem("accessToken"));
        this.cookie.set("userName", sessionStorage.getItem("userName"));

        const accessToken = sessionStorage.getItem("accessToken");
        const idTokenExp = new Date(
          sessionStorage.getItem("expiresOn")
        ).getTime();
        const currentTime = Date.now();

        if (idTokenExp < currentTime && accessToken) {
          // If idToken is expired, use accessToken for authorization
          this.cookie.set("accessToken", accessToken);
        } else {
          // If idToken is still valid, set it in cookies
          this.cookie.set("idToken", sessionStorage.getItem("idToken"));
        }
      });
  }

  getActiveAccount(): AccountInfo | null {
    return this.authService.instance.getActiveAccount();
  }

  private checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
    this.loggedInUser = this.authService.instance.getActiveAccount()
      ? this.authService.instance.getActiveAccount().name
      : this.loggedInUser;
  }

  private setLoggedIn() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  private loginWithPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          sessionStorage.setItem("idToken", response.idToken);

          sessionStorage.setItem("expiresOn", response.expiresOn.toString());
        });
    } else {
      this.authService
        .loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          sessionStorage.setItem("idToken", response.idToken);

          sessionStorage.setItem("expiresOn", response.expiresOn.toString());
        });
    }
  }

  private loginWithRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logout();
    this.cookie.deleteAll();
  }

  destroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
