import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { RestService } from "../common/rest.service";
import { RequestConfirmationDialogComponent } from "../request-confirmation-dialog/request-confirmation-dialog.component";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-update-project",
  templateUrl: "./update-project.component.html",
  styleUrls: ["./update-project.component.scss"],
})
export class UpdateProjectComponent implements OnInit {
  applicationData: any = [];
  isChecked = false;
  applications = new Array<string>();
  statusArray: any;
  default = "INACTIVE";
  action: string;
  isDisabled = false;
  edit = false;
  formArrayApplicationValues = [];
  isApplicationCheckedArray = [];
  languageArray = new Array();
  unselectedApplicationArray = new Array();
  selectedApplicationArray = new Array();
  appIcmmsArray = [];
  appsMap = new Map();
  applicationNames = [];
  dialogResult = false;
  unitArray: any;
  isSpinnerActive: boolean = false;

  projectForm = this.fb.group({
    id: [null],
    code: [
      null,
      [
        Validators.required,
        Validators.pattern("^([a-zA-Z]{2}[0-9]{4})|([a-zA-Z]{3}[0-9]{3})$"),
      ],
    ],
    name: [null, Validators.required],
    description: [null, Validators.required],
    status: [null, Validators.required],
    defaultLanguage: [null],
    remarks: [null],
    //unit: [null, Validators.required],
    t3000ProjectNameOld: [null],
    t3000ProjectName: [null],
  });
  // isFlexiChecklistSelected: boolean = false;

  constructor(
    private restService: RestService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<UpdateProjectComponent>,
    public confirmDialog: MatDialog,
    private spinner: NgxSpinnerService,
    private restApi: RestService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.action = this.data.action;
    const projectData = this.data.projectData;

    this.getLanguageOptions();
    this.getApplications(projectData);
    this.getStatusValues();
    //this.getUitValues();
  }
  getLanguageOptions() {
    this.restService.getLanguageOptions().subscribe(
      (res) => {
        this.languageArray = res;
      },
      (error) => {
        this.toastr.warning(error.error);
      }
    );
  }

  setModalValues(projectData) {
    if (projectData) {
      this.isDisabled = true;
      this.edit = true;

      const trimmedProjectName = projectData.name
        ? projectData.name.trim()
        : "";

      this.projectForm.setValue({
        id: projectData.id,
        code: projectData.code,
        name: trimmedProjectName,
        description: projectData.description,
        status: projectData.status,
        remarks: projectData.remarks,
        defaultLanguage: projectData.defaultLanguage,
        t3000ProjectName: projectData.t3000ProjectName,
        t3000ProjectNameOld: projectData.t3000ProjectName,
      });

      this.projectForm.setControl(
        "applicationName",
        this.fb.array(projectData.applicationName || [])
      );
      this.projectForm.setControl(
        "applicationActionUnselected",
        this.fb.array([])
      );
      this.projectForm.setControl(
        "applicationActionSelected",
        this.fb.array([])
      );
      // this.projectForm.setControl('appList', this.fb.array(projectData.appList || []));

      this.checkApplicationValues(
        this.applicationData,
        this.projectForm.controls.applicationName.value,
        projectData.appList
      );
    } else {
      for (const app of this.applicationData) {
        this.isApplicationCheckedArray.push({
          applicationName: app.name,
          checkboxValue: false,
          fromIcmms: false,
        });
      }
    }
  }

  checkApplicationValues(applicationData, formArray, appList) {
    console.log(appList);

    for (const form of formArray) {
      this.formArrayApplicationValues.push(form);
    }

    // tslint:disable-next-line: forin
    for (const value in appList) {
      this.appsMap.set(value, appList[value]);
    }

    this.appsMap.forEach((k, v) => {
      this.appIcmmsArray.push({ appName: k, Icmms: v });
    });

    for (const app of applicationData) {
      if (this.appsMap.has(app.name)) {
        this.isApplicationCheckedArray.push({
          applicationName: app.name,
          checkboxValue: true,
          fromIcmms: this.appsMap.get(app.name),
        });
        this.applicationNames.push(app.name);
      } else {
        this.isApplicationCheckedArray.push({
          applicationName: app.name,
          checkboxValue: false,
          fromIcmms: this.appsMap.get(app.name),
        });
      }
    }
  }

  getApplications(projectData) {
    this.restService.getActiveApplications().subscribe(
      (res) => {
        this.applicationData = res.filter((a) => a.name !== "ALL");

        this.setModalValues(projectData);
      },
      (error) => {
        this.toastr.warning(error.error);
      }
    );
  }

  getStatusValues() {
    this.statusArray = ["ACTIVE", "INACTIVE"];

    // this.adminService.getStatusValues()
    //   .subscribe((res) => {
    //     this.statusArray = res;
    //   }, error => {
    //     // this.showError("Oops! Something went wrong. Please try again later.");
    //   });
  }

  // getUitValues() {
  //   this.unitArray = ["0", "1", "2", "3", "4", "5"];
  // }

  onChangeEventFunc(applicationName: string, isChecked: boolean) {
    if (this.projectForm.controls.applicationName) {
      this.applicationNames = this.projectForm.controls.applicationName.value;
    }

    if (isChecked) {
      // if (applicationName.startsWith("FlexiChecklist")) {
      //   this.isFlexiChecklistSelected = true;
      // } else {
      //   this.isFlexiChecklistSelected = false;
      // }
      this.applicationNames.push(applicationName);
      this.selectedApplicationArray.push(applicationName);
      const index = this.unselectedApplicationArray.indexOf(applicationName);
      if (index >= 0) {
        this.unselectedApplicationArray.splice(index, 1);
      }
    } else {
      // this.isFlexiChecklistSelected = false;
      const index = this.applicationNames.indexOf(applicationName);
      this.applicationNames.splice(index, 1);
      const indexActionSelected =
        this.selectedApplicationArray.indexOf(applicationName);
      if (indexActionSelected >= 0) {
        this.selectedApplicationArray.splice(indexActionSelected, 1);
      }
      this.unselectedApplicationArray.push(applicationName);
    }
  }
  openDialog(
    titleName: string,
    msg: string
  ): MatDialogRef<RequestConfirmationDialogComponent> {
    const dialogConfirmRef = this.confirmDialog.open(
      RequestConfirmationDialogComponent,
      {
        width: "38%",
        data: {
          title: titleName,
          message: msg,
        },
      }
    );

    return dialogConfirmRef; // Return the dialog reference
  }

  syncApplications() {
    this.toastr.info("Application Sync started in the background...");

    this.isSpinnerActive = true;

    setTimeout(() => {
      this.spinner.show();
    }, 5000);

    this.restApi.syncAllApp().subscribe(
      (data) => {
        this.toastr.success(
          "Project Created Successful! Permissions are successfully updated and synced to the downstream application.",
          null,
          {
            timeOut: 3000,
          }
        );
        setTimeout(() => {
          this.spinner.hide();
          this.isSpinnerActive = false;
        }, 3000);

        window.top.location.reload();
      },
      (error) => {
        if (error && error.error) {
          this.toastr.error(error.error.error, "HTTP Status: " + error.status);
        } else {
          this.toastr.error(
            "An error occurred",
            "HTTP Status: " + error.status
          );
        }
        console.error(JSON.stringify(error));

        this.spinner.hide();
        this.isSpinnerActive = false; // Hide spinner if there's an error
      }
    );
  }

  closeForm() {
    this.onClose(true);
  }

  onSubmit() {
    if (this.projectForm.invalid) {
      return;
    }

    let message = "Do you want to make the project inactive ?";

    if (this.projectForm.value.status === "ACTIVE") {
      if (this.applicationNames.length === 0) {
        message =
          "Application is not mapped. This will revoke the existing user(s) access. Do you wish to continue ?";
        this.confirmAndProceed(message, this.request.bind(this));
      } else {
        this.request();
        this.syncApplications();
      }
    } else {
      if (this.action === "EDIT") {
        message =
          "Inactivating the project will revoke the user(s) access.\n Do you wish to continue ?";
        this.confirmAndProceed(message, this.syncApplications.bind(this));
      } else if (this.action === "NEW") {
        this.request();
        this.syncApplications();
      }
    }
  }

  confirmAndProceed(message: string, onConfirmCallback: () => void): void {
    const dialogRef = this.openDialog("Confirmation", message);

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        onConfirmCallback(); // Proceed with the provided function
        this.syncApplications(); // Sync only after confirmation
      }
    });
  }

  request() {
    this.projectForm.setControl(
      "applicationName",
      this.fb.array(this.applicationNames || [])
    );
    this.projectForm.setControl(
      "applicationActionUnselected",
      this.fb.array(this.unselectedApplicationArray)
    );
    this.projectForm.setControl(
      "applicationActionSelected",
      this.fb.array(this.selectedApplicationArray)
    );

    if (this.projectForm.invalid) {
      return;
    }

    if (this.action === "EDIT") {
      this.restService.updateProject(this.projectForm.value).subscribe(
        (res) => {
          this.onClose(true);
          // window.top.location.reload();
        },
        (error) => {
          this.onClose(true);
          this.toastr.warning(error.error.message);
        }
      );
    } else if (this.action === "NEW") {
      this.restService.createProject(this.projectForm.value).subscribe(
        (res) => {
          this.onClose(true);
          // window.top.location.reload();
        },
        (error) => {
          this.toastr.warning(error.error.message);
        }
      );
    }
  }

  onClose(result: boolean): void {
    this.dialogRef.close(result);
  }
}
