import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RestService } from '../common/rest.service';
import swal from 'sweetalert2';

@Component({
   selector: 'app-create-role',
   templateUrl: './create-role.component.html',
   styleUrls: ['./create-role.component.scss']
})
export class CreateRoleComponent implements OnInit {

   statusList: string[] = ['ACTIVE', 'INACTIVE'];
   activeRoles = [];
   activeUsers = [];
   readonly = false;
   edit = false;
   dialogResult = false;
   roleName = '';

   roleForm = this.formBuilder.group({
      id: [null],
      name: [null, Validators.required],
      status: [null, Validators.required],
      approver: [null, Validators.required],
      remarks: [null, Validators.maxLength(255)],
      signup: [true],
      manage_role: [false],
      manage_application: [false],
      manage_project: [false],
      onboarding_approval: [false],
      manage_user: [false],
      onboarding_history: [false]
   });

   constructor(public dialogRef: MatDialogRef<CreateRoleComponent>, private toastr: ToastrService,
               public restApi: RestService, public router: Router, private formBuilder: FormBuilder,
               @Inject(MAT_DIALOG_DATA) public Data: any) { }

   ngOnInit() {
      const roleData = this.Data.roleData;
      this.restApi.getRoles().subscribe(roles => {
         this.activeRoles = roles.filter(r => r.status === 'ACTIVE');

         if (roleData) {
            if (roleData.name === 'Super Admin') {
               this.activeRoles = this.activeRoles.filter(r => r.name === 'Super Admin');
            }
            this.readonly = true;
            this.roleForm.setValue({
               id: roleData.id,
               name: roleData.name,
               status: roleData.status,
               approver: roleData.approver,
               remarks: roleData.remarks,
               signup: roleData.signup,
               manage_role: roleData.manage_role,
               manage_application: roleData.manage_application,
               manage_project: roleData.manage_project,
               onboarding_approval: roleData.onboarding_approval,
               manage_user: roleData.manage_user,
               onboarding_history: roleData.onboarding_history
            });
         }
      });
   }

   onSubmit(roleForm) {
      if (this.roleForm.invalid) {
         return;
      }
      const roleId = this.roleForm.value.id;
      if (this.roleForm.value.status === 'INACTIVE' && this.roleForm.value.name === 'Super Admin') {
         swal('', 'Super Admin can not be marked as inactive', 'warning');
         return;
      } else if (this.roleForm.value.status === 'INACTIVE') {
         this.restApi.getActiveUsers(roleId).subscribe(users => {
            if (users.length > 0) {
               swal('', 'This role has active users so cannot be marked as inactive', 'warning');
               return;
            } else {
               this.saveData(roleForm);
            }
         });
      } else {
         this.saveData(roleForm);
      }
   }

   saveData(roleForm) {
      this.restApi.createRole(roleForm).subscribe(result => {
         this.toastr.success(result.response, 'Update', { timeOut: 5000 });
         this.onClose(true);
      }, error => {
         this.toastr.error(error.error.message, 'Update', { timeOut: 5000 });
      });
   }

   onClose(result: boolean): void {
      this.dialogRef.close(result);
   }
}
