import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { FooterComponent } from "./common/footer/footer.component";

import { HeaderComponent } from "./common/header/header.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AllMaterialModule } from "./common/material-module";
import { UserManagementComponent } from "./common/user-management/user-management.component";
import { LandingComponent } from "./landing/landing.component";
import { ToastrModule } from "ngx-toastr";
import { LoginComponent } from "./login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ManageProjectComponent } from "./manage-project/manage-project.component";
import { UpdateProjectComponent } from "./update-project/update-project.component";
import { ManageApplicationsComponent } from "./manage-applications/manage-applications.component";
import { CreateApplicationComponent } from "./create-application/create-application.component";
import { AddUserComponent } from "./add-user/add-user.component";
import { CreateRoleComponent } from "./create-role/create-role.component";
import { ManageRoleComponent } from "./manage-role/manage-role.component";
import { AdminComponent } from "./admin/admin.component";
import { OnboardUserComponent } from "./onboard-user/onboard-user.component";
import { ManageUserComponent } from "./manage-user/manage-user.component";
import { AdminApprovalComponent } from "./admin-approval/admin-approval.component";
import { OnboardingHistoryComponent } from "./onboarding-history/onboarding-history.component";
import { RequestConfirmationDialogComponent } from "./request-confirmation-dialog/request-confirmation-dialog.component";
import { RestService } from "./common/rest.service";
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { InterceptorService } from "./common/token-interceptor.service";
import { CookieService } from "ngx-cookie-service";
import { DataService } from "./common/data.service";
import { TermsOfUseComponent } from "./common/terms-of-use/terms-of-use.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { NgxSpinnerModule } from "ngx-spinner";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  ProtocolMode,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MsalRedirectComponent,
} from "@azure/msal-angular";
import { MsalAuthService } from "./msal-auth.service";
import { AuthGuard } from "./common/auth.guard";
import { CreateUserRoleComponent } from "./create-user-role/create-user-role.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: "6d3e1301-fb13-42a9-b51a-9536fafffe04",
      authority:
        "https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477/",
      redirectUri: "/dashboard",
      postLogoutRedirectUri: "/",
      protocolMode: ProtocolMode.OIDC,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage, // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
      secureCookies: true,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        "openid",
        "profile",
        "email",
        "https://graph.microsoft.com/.default",
        "offline_access",
        "User.Read.All",
      ],
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LandingComponent,
    LoginComponent,
    ManageProjectComponent,
    UpdateProjectComponent,
    ManageApplicationsComponent,
    CreateApplicationComponent,
    AddUserComponent,
    CreateRoleComponent,
    ManageRoleComponent,
    AdminComponent,
    OnboardUserComponent,
    ManageUserComponent,
    AdminApprovalComponent,
    UserManagementComponent,
    OnboardingHistoryComponent,
    RequestConfirmationDialogComponent,
    TermsOfUseComponent,
    DashboardComponent,
    CreateUserRoleComponent,
    ConfirmationDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ preventDuplicates: true }),
    FlexLayoutModule,
    AllMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    MsalModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
  ],
  providers: [
    RestService,
    DataService,
    MsalAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    CookieService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthGuard,
  ],

  bootstrap: [AppComponent, MsalRedirectComponent],
  entryComponents: [
    LoginComponent,
    RequestConfirmationDialogComponent,
    TermsOfUseComponent,
    ConfirmationDialogComponent,
  ],
})
export class AppModule {}
