import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MsalAuthService} from '../msal-auth.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  constructor(private auth: MsalAuthService, private router: Router) {
  }

  ngOnInit() {
    if (this.auth.loggedIn) {
      this.router.navigate(['/dashboard']);
    }
  }

}
