import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatDialog, MatPaginator, MatSortable } from '@angular/material';
import { Router } from '@angular/router';
import { saveFile } from 'src/app/common/file-download-helper';
import { formatDate } from '@angular/common';
import { RestService } from '../common/rest.service';
import {NgxSpinnerService} from 'ngx-spinner';


@Component({
  selector: 'app-onboarding-history',
  templateUrl: './onboarding-history.component.html',
  styleUrls: ['./onboarding-history.component.scss']
})
export class OnboardingHistoryComponent implements OnInit {
  displayedColumns: string[] = ['gid', 'name', 'project', 'application', 'approvedTime', 'approvedBy', 'revokedTime', 'revokedBy'];
  dataSource: MatTableDataSource<any>;
  onboardUserList: any[];


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private toastr: any;

  constructor(private router: Router, private restApi: RestService, private spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.spinner.show();
    this.restApi.getOnboardedHistory().subscribe((data: any[]) => {
      this.prepareOnboardUserData(data);
      this.spinner.hide();
      this.sort.sort({ id: "approvedTime", start: "desc" } as MatSortable);
      },
      (error) => {
        this.spinner.hide();
        this.toastr.warning(error.error.message || 'Failed to fetch onboarded history.');
      }
    );
  }

  prepareOnboardUserData(data: any[]) {
    this.onboardUserList = data;
    this.dataSource = new MatTableDataSource(this.onboardUserList);
    // this.sort.sort(({ id: 'approvalDate', start: 'asc' }) as MatSortable);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'approvedTime':
          return new Date(item.approvedTime).getTime();
        case 'revokedTime':
          return new Date(item.revokedTime).getTime();
        default:
          return item[property];
      }
    };
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  exportTOCSV() {
    if (this.onboardUserList.length > 0) {
      const replacer = (key, value) => value === null ? '' : value;
      let header: string[] = Object.keys(this.onboardUserList[0]);
      // header.splice(header.findIndex(head => head === 'application'), 1);
      const csv = this.onboardUserList.map(row => header.map( fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      header = header.map(fieldName => fieldName.replace(/([A-Z]+)/g, ' $1').toLocaleUpperCase());
      csv.unshift(header.join(','));
      const csvArray = csv.join('\r\n');
      const currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US', '+0530');
      const fileName = 'Onboarding_History-' + currentDate + '.csv';

      saveFile(csvArray, fileName, 'text/csv');
    }
  }
}
