import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RestService } from '../common/rest.service';
import { RequestConfirmationDialogComponent } from '../request-confirmation-dialog/request-confirmation-dialog.component';

@Component({
  selector: 'app-create-application',
  templateUrl: './create-application.component.html',
  styleUrls: ['./create-application.component.scss']
})
export class CreateApplicationComponent implements OnInit {
  statusList: string[] = ['ACTIVE', 'INACTIVE'];
  action: string;
  readonly: string;
  applicationDetailForm = this.formBuilder.group({
    id: [null],
    name: [null, Validators.required],
    description: [null, Validators.required],
    status: [null, Validators.required],
    remarks: [null]
  });
  projectArray: any = [];
  dialogResult = false;

  constructor(
    public dialogRef: MatDialogRef<CreateApplicationComponent>,
    public restApi: RestService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public confirmDialog: MatDialog
  ) {
    dialogRef.disableClose = true;
  }

  openDialog(titleName: string, msg: string): void {
    const dialogConfirmRef = this.confirmDialog.open(RequestConfirmationDialogComponent, {
      width: '38%',
      data: {
        title: titleName,
        message: msg
      }
    });

    dialogConfirmRef.afterClosed().subscribe(result => {
      this.dialogResult = result;
      if (this.dialogResult) {
        this.request();
        this.dialogResult = false;
      }
      console.log('The confirm window was closed');
    });
  }

  ngOnInit() {
    this.action = this.data.action;
    const applicationData = this.data.applicationData;
    if (applicationData) {
      this.applicationDetailForm.setValue({
        id: applicationData.id,
        name: applicationData.name,
        description: applicationData.description,
        status: applicationData.status,
        remarks: applicationData.remarks
      });
    }
    if (this.action === 'EDIT') {

      this.getProjects(applicationData.id);

      this.readonly = 'true';
    }
  }

  getProjects(id) {
    this.restApi.getProjectsByApplication(id).subscribe((res) => {
      this.projectArray = res;
    }, error => {
      this.toastr.warning(error.error.message);
    });
  }

  onClose(result: boolean): void {
    this.dialogRef.close(result);
  }

  onSubmit() {
    if (this.applicationDetailForm.invalid) {
      return;
    }
    let message = 'Do you want to make the application inactive ?';
    if (this.applicationDetailForm.value.status === 'ACTIVE') {
      this.request();
    } else {
      if (this.projectArray.length > 0) {
        message = this.applicationDetailForm.value.name + ' is mapped to ' + this.projectArray.length +
        ' projects.\n Do you want to make it inactive ?';
      }
      this.openDialog('Confirmation', message);
    }

  }

  request() {
    console.log(this.applicationDetailForm.value);
    if (this.action === 'NEW') {
      console.log('New');
      this.restApi.createApplication(this.applicationDetailForm.value).subscribe(result => {
        console.log(result);
        this.toastr.success(result.response, 'Create', { timeOut: 5000 });
        this.onClose(true);
      }, error => {
        this.toastr.error(error.error.message, 'Create', { timeOut: 5000 });
      });
    } else if (this.action === 'EDIT') {
      this.restApi.editApplicationDetail(this.applicationDetailForm.value).subscribe(result => {
        console.log(result);
        this.toastr.success(result.response, 'Update', { timeOut: 5000 });
        this.onClose(true);
      }, error => {
        this.toastr.error(error.error.message, 'Update', { timeOut: 5000 });
      });
    }
  }

}
