import { Component, OnInit } from '@angular/core';
import { RestService } from '../rest.service';
import { MatDialogRef, MatCheckboxChange } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import {MsalAuthService} from '../../msal-auth.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  isAgreeDisabled = true;

  constructor(private restService: RestService, public dialogRef: MatDialogRef<TermsOfUseComponent>,
              public authService: MsalAuthService, private toastr: ToastrService) {
    dialogRef.disableClose = true;
   }

  ngOnInit() {
  }

  onChangeEventFunc(event: MatCheckboxChange) {
    console.log('isChecked : ', event.checked);
    if (event.checked) {
      this.isAgreeDisabled = false;
      console.log('isAgreeDisabled', this.isAgreeDisabled);
    } else {
      this.isAgreeDisabled = true;
    }
  }

  onAgree() {

    this.restService.approveTerms(true).subscribe(res => {
      this.onClose(true);

    }, error => {
      this.toastr.warning(error.error.message);
      });

  }

  onCancel() {
    this.toastr.error('Please Accept the Terms and Conditions to access the application.');
    sessionStorage.clear();
    localStorage.clear();
    this.authService.logout();
  }

  onClose(result: boolean): void {
    this.dialogRef.close(result);
  }

}
