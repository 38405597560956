import { Inject, Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { EMPTY, Observable, Subscription, throwError } from "rxjs";
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from "@azure/msal-angular";
import { AuthenticationResult, SilentRequest } from "@azure/msal-browser";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { AuthService } from "../auth.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class InterceptorService implements HttpInterceptor {
  private accessToken: string | string[];
  private subscription: Subscription;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msal: MsalService,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private cookie: CookieService
  ) {
    this.authService.accessToken$.subscribe(
      (accessToken) => (this.accessToken = accessToken)
    );

    if (!this.accessToken) {
      this.accessToken =
        sessionStorage.getItem("accessToken") || cookie.get("accessToken");
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const idToken = sessionStorage.getItem("idToken");
    const accessToken = sessionStorage.getItem("accessToken");

    if (idToken && !this.isTokenExpired(idToken)) {
      // Use idToken if it's not expired
      return this.handleRequestWithToken(req, next, idToken);
    } else if (accessToken && !this.isTokenExpired(accessToken)) {
      // Use accessToken if idToken is expired but accessToken is valid
      return this.handleRequestWithToken(req, next, accessToken);
    } else if (sessionStorage.getItem("auth") === "azure") {
      // Try to acquire a new idToken silently
      return this.acquireTokenSilently(req, next, accessToken);
    } else {
      // Handle non-Azure authentication's
      return this.handleNonAzureAuth(req, next);
    }
  }

  private handleRequestWithToken(
    req: HttpRequest<any>,
    next: HttpHandler,
    token: string
  ): Observable<HttpEvent<any>> {
    const headers = req.headers.set("Authorization", `Bearer ${token}`);
    const requestClone = req.clone({ headers });
    return next.handle(requestClone);
  }

  private acquireTokenSilently(
    req: HttpRequest<any>,
    next: HttpHandler,
    accessToken: string
  ): Observable<HttpEvent<any>> {
    return this.msal
      .acquireTokenSilent({
        ...this.msalGuardConfig.authRequest,
      } as SilentRequest)
      .pipe(
        catchError(() => {
          // If idToken acquisition fails, fallback to accessToken
          if (accessToken && !this.isTokenExpired(accessToken)) {
            return this.handleRequestWithToken(req, next, accessToken);
          }
          // else {
          //   this.handleTokenError();
          //   return EMPTY;
          // }
        }),
        switchMap((result: AuthenticationResult) => {
          sessionStorage.setItem("idToken", result.idToken);
          sessionStorage.setItem("expiresOn", result.expiresOn.toString());
          return this.handleRequestWithToken(req, next, result.idToken);
        })
      );
  }

  private handleNonAzureAuth(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.getTokenSilently$().pipe(
      switchMap((token) => {
        return this.handleRequestWithToken(req, next, token);
      }),
      catchError((error) => {
        if (error && error.error && error.error.error === "Unauthorized") {
          this.handleTokenError();
        }
        return throwError(error);
      })
    );
  }

  private handleTokenError() {
    this.toastr.error("Token error", "Error");
    sessionStorage.clear();
    localStorage.clear();
    this.cookie.deleteAll();
    this.authService.logout();
  }

  private isTokenExpired(token: string): boolean {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return Date.now() >= payload.exp * 1000;
  }
}
