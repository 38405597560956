import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, ActivatedRoute, RouterState,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { CookieService } from 'ngx-cookie-service';
import {MsalGuard} from "@azure/msal-angular";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private msalGuard: MsalGuard,
    public router: Router,
    private cookie: CookieService,
    private route: ActivatedRoute
  ) {}

  canActivate(): boolean {
    if (sessionStorage.getItem("auth") === "myid") {
      if (!sessionStorage.getItem('accessToken')) {
        if (this.cookie.get('auth0.is.authenticated') !== 'true') {
          console.log('not authenticated');
          return false;
        } else if (
          this.cookie.get('accessToken') &&
          this.cookie.get('auth0.is.authenticated') === 'true' &&
          window.location.pathname === '/'
        ) {
          console.log('inside the condition');
          return true;
        }
      }
      return true;
    } else if(sessionStorage.getItem("auth") === "azure"){
      return true;
    }
  }
}
