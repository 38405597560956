import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class DataService {

  private count = new BehaviorSubject<number>(null);
  count$: Observable<number> = this.count.asObservable();

  requestCount = this.count.asObservable();

  constructor() {}

  updateRequestCount( count: number) {
    this.count.next(count);
  }

}
