import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatDialog, MatPaginator, MatSortable } from '@angular/material';
import { Router } from '@angular/router';
import { CreateApplicationComponent } from '../create-application/create-application.component';
import { RestService } from '../common/rest.service';
import {NgxSpinnerService} from 'ngx-spinner';

export interface ApplicationData {
  id: number;
  name: string;
  description: string;
  status: string;
  remarks: string;
}

@Component({
  selector: 'app-manage-applications',
  templateUrl: './manage-applications.component.html',
  styleUrls: ['./manage-applications.component.scss']
})
export class ManageApplicationsComponent implements OnInit {
  applicationPageTitle = 'Manage Application';
  displayedColumns: string[] = ['name', 'description', 'status', 'action'];
  dataSource: MatTableDataSource<ApplicationData>;
  applicationsList: ApplicationData[];
  errorMessage: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private toastr: any;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private restApi: RestService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.restApi.getApplications().subscribe((data: ApplicationData[]) => {
      this.prepareApplicationTableData(data);
      this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.errorMsg);
        this.toastr.warning(error.error.message || 'Failed to fetch applications.');
      }
    );
  }

  prepareApplicationTableData(data) {
    this.applicationsList = data;
    // Assign the application to the data source for the table to render
    this.dataSource = new MatTableDataSource<ApplicationData>(
      this.applicationsList
    );
    // this.sort.sort(({ id: 'status', start: 'asc' }) as MatSortable);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  validateLength(length) {
    if(length > 10) {
      return true;
    }
    else
      return false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }

  openApplicationDetailDialog(act, aData): void {
    const dialogRef = this.dialog.open(CreateApplicationComponent, {
      width: '600px',
      data: { applicationData: aData, action: act },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.restApi.getApplications().subscribe((data: ApplicationData[]) => {
          this.prepareApplicationTableData(data);
        });
      }
    });
  }

  // editApplicationData(id) {
  //   console.log(id);
  //   this.router.navigate(["/edit-application/", { id: id }]);
  // }
}
