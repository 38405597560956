import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = this.formBuilder.group({
    name: [null],
    gid: [null, Validators.required]
  });

  constructor(private router: Router,
              public dialogRef: MatDialogRef<LoginComponent>,
              private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    const userid = '';
    this.loginForm.setValue({
      name: userid,
      gid: userid
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  goToDashboard(loginForm) {
    console.log(loginForm);
    if (this.loginForm.invalid) {
      return;
    }
    const gid = this.loginForm.value.gid;
    localStorage.setItem('gid', gid);
    localStorage.setItem('token', '');

    this.dialogRef.close();
    this.router.navigate(['usermanagement/onboardUser']);
    window.top.location.reload();
  }

}
