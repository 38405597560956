import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { CreateRoleComponent } from '../create-role/create-role.component';
import { RestService } from '../common/rest.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-manage-role',
  templateUrl: './manage-role.component.html',
  styleUrls: ['./manage-role.component.scss']
})
export class ManageRoleComponent implements OnInit {
  displayedColumns: string[] = ['name', 'remarks', 'status', 'approver', 'action'];

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private toastr: any;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private restApi: RestService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.getRoles();
  }

  getRoles() {
    this.spinner.show();
    this.restApi.getRoles().subscribe((d) => {
      console.log(d);
      this.dataSource = new MatTableDataSource(d);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.warning(error.error.message);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(role): void {
    const dialogRef = this.dialog.open(CreateRoleComponent, {
      width: '700px',
      data: { roleData: role },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getRoles();
      }
    });
  }

  editRole(roleId) {
    this.router.navigate(['/role-edit/', { id: roleId }]);
  }

}
