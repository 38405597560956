import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Url} from 'url';
import {AuthService} from "../../auth.service";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() loggedIn: boolean;
  @Input() loggedInUser: string;
  @Output() loginAzureAdClicked = new EventEmitter<void>();
  @Output() loginMyIdClicked = new EventEmitter<void>()
  @Output() logoutClicked = new EventEmitter<void>();

  constructor() { }

  userImage: Url;

  ngOnInit() {
  }

  loginWithAzureAd() {
    console.log(1,"Logging in using azure");
    this.loginAzureAdClicked.emit();
  }

  logout() {
    this.logoutClicked.emit();
  }

  loginWithMyId() {
    console.log(1,"Logging in using myId");
    this.loginMyIdClicked.emit();
  }
}
